//MIGRACION 1 - GESTION RECIBOS EXTERNOS: SE UNIFICA Y SE ELIMINAN LOS STORES DE GENDARMERIA Y PENITENCIARIA
import {
    getRecibosExternosPorCUIT,
    getRecibosExternosPorBeneficio,
    getRolesUsuarioExterno,
} from "../../../api/gestionrecibosexternos";

const state = {
    recibos: [],
    recibosBuscados: false,
    loading: false,
    beneficios: [],
    apoderados: [],
    persona: [],
    errors: [],
    roles_externos: [],
};

const getters = {};

const mutations = {
    resetState(state) {
        state.recibos = [];
        state.recibosBuscados = false;
        state.loading = false;
        state.beneficios = [];
        state.apoderados = [];
        state.persona = [];
        state.errors = [];
    },
    setBeneficios(state, beneficios) {
        //Devuelve Objeto Beneficios
        state.beneficios = beneficios;
    },
    setApoderados(state, apoderados) {
        state.apoderados = apoderados;
    },
    setRecibos(state, recibos, beneficios) {
        state.recibos = recibos;
    },
    setPersona(state, persona) {
        state.persona = persona;
    },
    emptyErrors(state) {
        state.errors = [];
    },
    setError(state, error) {
        state.errors = error;
    },
    setLoading(state, active) {
        state.loading = active;
    },
    setRecibosBuscados(state, buscado) {
        state.recibosBuscados = buscado;
    },
    setRolesExternos(state, roles_externos) {
        state.roles_externos = roles_externos;
    },
};
const actions = {
    limpiarDatos({ commit }) {
        commit("resetState");
    },

    obtenerRolesUser({ commit }) {
        getRolesUsuarioExterno()
            .then((response) => {
                commit("setRolesExternos", response.data);
            })
            .finally();
    },

    async traerRecibosPorCUIT({ commit }, { cuit, opcion }) {
        commit("setLoading", true);
        try {
            commit("setError", []);
            const { data } = await getRecibosExternosPorCUIT(cuit, opcion);
            const { beneficios, apoderados, recibos, ...persona } = data;
            const recibosArray = [];
            for (const [beneficioKey, arrayRecibos] of Object.entries(
                recibos
            )) {
                arrayRecibos.forEach((recibo) => {
                    recibosArray.push(recibo);
                });
            }
            commit("setBeneficios", beneficios);
            commit("setApoderados", apoderados);
            commit("setRecibos", recibosArray);
            commit("setPersona", persona);
            commit("setLoading", false);
            commit("setRecibosBuscados", true);
        } catch (e) {
            commit("setBeneficios", []);
            commit("setApoderados", []);
            commit("setRecibos", []);
            commit("setPersona", []);
            commit("setError", [e.response.data.error]);
            commit("setLoading", false);
            commit("setRecibosBuscados", false);
        }
    },
    async traerRecibosPorBeneficio({ commit }, { beneficio, opcion }) {
        commit("setLoading", true);
        try {
            commit("setError", []);
            const { data } = await getRecibosExternosPorBeneficio(
                beneficio,
                opcion
            );
            const { beneficios, apoderados, recibos, ...persona } = data;
            const recibosArray = [];

            for (const [beneficioKey, arrayRecibos] of Object.entries(
                recibos
            )) {
                arrayRecibos.forEach((recibo) => {
                    recibosArray.push(recibo);
                });
            }
            commit("setBeneficios", beneficios);
            commit("setApoderados", apoderados);
            commit("setRecibos", recibosArray);
            commit("setPersona", persona);
            commit("setLoading", false);
            commit("setRecibosBuscados", true);
        } catch (e) {
            commit("setBeneficios", []);
            commit("setApoderados", []);
            commit("setRecibos", []);
            commit("setPersona", []);
            commit("setError", [e.response.data.error]);
            commit("setLoading", false);
            commit("setRecibosBuscados", false);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
