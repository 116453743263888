<!-- MIGRACION 1 - GESTION RECIBOS EXTERNOS: SE UNIFICA Y SE ELIMINAN LOS componentes  DE GENDARMERIA Y PENITENCIARIA -->
<template>
    <div>
        <div v-if="recibosEncontrados?.length > 0">
            <transition-group appear name="mis-recibos-externos" id="mis-recibos-externos" tag="div" mode="out-in"
                v-for="(recibo, index) in recibosEncontrados" :key="recibo['ruta_recibo']" :index="index">
                <recibo :key="recibo['ruta_recibo']" :recibo="recibo" :index="index" :persona="persona"
                    :opcion="opcion" />
            </transition-group>
        </div>
        <div v-else-if="recibosEncontrados?.length == 0 && recibosBuscados" class="card">
            <div class="card-body">No se encontraron Recibos para ese CUIT / BENEFICIO</div>
        </div>
    </div>
</template>

<script>
import Recibo from '../recibo/Recibo.vue'
import { mapState } from 'vuex';

export default {
    name: "ListaRecibosExternos",
    props: ["recibosEncontrados", "persona", "opcion"],
    components: {
        Recibo
    },
    data() {
        return {
            organismo: ""
        }
    },

    computed: {
        ...mapState({
            recibosBuscados: state => {
                return state.gestionexternos.recibosBuscados
            }

        })
    },
}
</script>

<style lang="scss" scoped></style>
