<template>
    <div class="container-fluid grid">
        <div class="card_usuario row">
            <div class="col-12">
                <p class="card-title">{{ usuario.persona.nombre }} {{ usuario.persona.apellido }}</p>
                <a v-if="usuario.email" href="mailto:'{ usuario.email }">Email: {{ usuario.email }}</a>
                <a v-if="usuario.email_alternativo && usuario.email_alternativo != usuario.email"
                    href="mailto:'{ usuario.email_alternativo }">Email alternativo:{{
                        usuario.user.email_alternativo }}</a>
            </div>
            <div class="col-12">
                <p>Cuil: {{ usuario.persona.cdi_cuil }}</p>
                <p>Fuerzas:
                    <span v-for="(rol, index) in usuario.roles_externos">
                        {{ rol.nombre_rol }}{{ index == (usuario.roles_externos.length - 1) ? '.' : ', ' }}
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: [
        'usuario',
        'index',
    ],
};
</script>
