<template>
    <div class="row align-items-center">
        <div class="col-5 justify-self-center">
            <h5>Desde:</h5>
            <vue-date-picker class="w-100" v-model="startDate" @pick="updateFechaInicio" :disabled-date="limitDates"
                type="month" />
        </div>
        <div class="col-5">
            <h5>Hasta:</h5>
            <vue-date-picker class="w-100" v-model="endDate" @pick="updateFechaFinalizacion" :disabled-date="limitDates"
                type="month" />
        </div>

        <button class="btn btn-danger align-self-end  reset_button  col-2" @click="handleReset">Borrar</button>
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';

export default {
    emits: ['fechaInicio', 'fechaFin'],
    props: ['fecha_tope_inicio'],
    data() {
        return {
            startDate: null,
            endDate: null,
            maxDate: new Date(),
            minDate: null
        }
    },

    created() {
        this.endDate = moment().format()
        this.minDate = this.$props.fecha_tope_inicio
    },

    methods: {

        limitDates(date) {
            return (date >= this.maxDate || date < this.$props.fecha_tope_inicio)
        },

        handleReset() {
            this.startDate = null
            this.endDate = null
            this.$emit('fechaInicio', this.startDate)
            this.$emit('fechaFin', this.endDate)
        },

        updateFechaInicio(value) {
            this.startDate = value
            this.$emit('fechaInicio', moment(value).format("YYYY-MM"))

        },

        updateFechaFinalizacion(value) {
            this.endDate = value
            this.$emit('fechaFin', moment(value).format("YYYY-MM"))

        },
    },

    computed: {

    },

    components: {
        vueDatePicker: DatePicker
    }
}
</script>
<style lang="scss">
.reset_button {
    height: 80%
}
</style>
