<template>
    <div class="container-fluid usuarios_empleados_buscar">
        <div class="actions_usuarios d-flex justify-content-between">
            <div class="d-inline-flex">
                <div class="card action_card" @click="mostrarFormularioUsuario()">
                    <i class="fas fa-check-circle text-center"> </i>
                    <p class="text-center">Agregar Usuario</p>
                </div>
                <div class="card action_card" @click="mostrarFormularioFuerza()">
                    <i class="fas fa-check-circle text-center"></i>
                    <p class="text-center">Agregar Fuerza</p>
                </div>
            </div>


        </div>

        <div class="card mt-2" v-if=showUserForm>
            <div class="card-title ml-2 mt-2">
                <h3>Crear Usuario</h3>
            </div>
            <div class="card-body">
                <div class="form p-3 ">
                    <div class="form-group">
                        <label>Tipo de documento</label>
                        <select class="form-control" name="tipo_documento_usuario" id="tipo_documento_usuario"
                            v-model="usuario_nuevo.tipo_documento_usuario">
                            <option value="">Tipo Dni...</option>
                            <option v-for="tipo in tipoDni" :value="tipo">
                                {{ tipo }}
                            </option>
                        </select>
                        <label>Numero de documento</label>
                        <input class="form-control" type="text" name="usuario_nuevo" id="numero_documento"
                            placeholder="Ej: 11222333" v-model="usuario_nuevo.numero_documento" />
                    </div>
                    <div class="form-group">
                        <label>CUIT</label>
                        <input class="form-control" type="text" name="usuario_nuevo" id="cuit"
                            placeholder="Ej: 23112223334" v-model="usuario_nuevo.cuit" />
                    </div>
                    <div class="form-group">
                        <label>Nombre</label>
                        <input class="form-control" type="text" name="usuario_nuevo" id="nombre" placeholder="Ej: Jorge"
                            v-model="usuario_nuevo.nombre" />
                    </div>
                    <div class="form-group">
                        <label>Apellido</label>
                        <input class="form-control" type="text" name="usuario_nuevo" id="apellido"
                            placeholder="Ej: Lopez" v-model="usuario_nuevo.apellido" />
                    </div>
                    <div class="form-group">
                        <label>Email</label>
                        <input class="form-control" type="text" name="usuario_nuevo" id="apellido"
                            placeholder="Ej: email@crjppf.gob.ar" v-model="usuario_nuevo.email" />
                    </div>
                    <div class="form-group">
                        <label>Fecha de Nacimiento</label>
                        <input class="form-control" type="date" name="usuario_nuevo" id="dob" placeholder="Ej: 11222333"
                            @change="event => usuario_nuevo.dob = event.target.value" />
                    </div>
                    <div class="form-group">
                        <label>Fuerza</label>
                        <select class="form-control" name="fuerza_usuario" id="fuerza_usuario"
                            v-model="usuario_nuevo.fuerza_nuevo_usuario">
                            <option value="">Seleccionar</option>
                            <option v-for="fuerza in fuerzas" :value="fuerza.id">
                                {{ fuerza.nombre_rol }}
                            </option>
                        </select>
                    </div>

                </div>
            </div>
            <div class="alert alert-danger alert-dismissible fade show m-2" role="alert" v-if="error_exist">
                {{ error_message }}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="() => {
                    error_message = '',
                        error_exist = false

                }">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="card-actions text-right mb-2 mr-2">
                <button class="btn btn-primary" @click="agregarUsuario">
                    Crear
                </button>
            </div>
        </div>
        <div class="card  mt-2" v-if=showRolExternoForm>
            <div class="card-title ml-2 mt-2">
                <h3>Crear Nuevo Rol</h3>
            </div>
            <div class="card-body">
                <div class="form p-3">
                    <div class="form-group">
                        <label>Nuevo rol Externo</label>
                        <input class="form-control" type="text" name="rol_externo" id="nombre"
                            placeholder="Ej: PENITENCIARIA" v-model="rolExternoNuevo" />
                    </div>
                </div>
            </div>
            <div class="card-actions text-right mb-2 mr-2">
                <button class="btn btn-primary" @click="agregarRolExterno">
                    Agregar rol
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-12">

                <form class="formulario" v-on:submit.prevent>
                    <h4 class="busqueda-transferencias__titulo mb-3">
                        Buscar usuarios externos
                    </h4>
                    <div class="row">
                        <div class="form-group select-container col-12 col-md-4">
                            <label for="select">Buscar por:</label>
                            <select class="form-control" name="select" id="select" v-model="select">
                                <option value="1">Mail Usuario</option>
                                <option value="2">Fuerza</option>
                            </select>
                        </div>
                        <div class="form-group col-12 col-md-8" v-if="select === '1'">
                            <label class="d-block" for="usuario">Buscar</label>
                            <div class="buscador-usuario">
                                <input class="form-control" type="text" name="usuario" id="usuario"
                                    placeholder="Buscar por email crjppf" v-model="usuario" />
                                <button class="btn btn-primary" @click="buscarUsuarioExternos(usuario)">
                                    <i class="fas fa-search px-5"></i>
                                </button>
                            </div>
                        </div>
                        <div class="form-group select-container col-12 col-md-8" v-if="select === '2'">
                            <label for="sector">Rol Externo</label>
                            <select class="form-control" name="sector" id="sector" v-model="fuerza_seleccionada">
                                <option value="">Todas las fuerzas</option>
                                <option v-for="fuerza in fuerzas" :value="fuerza.id">
                                    {{ fuerza.nombre_rol }}
                                </option>
                            </select>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="mensaje">
            {{ mensaje }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="mensaje = ''">
                <span aria-hidden="true">&times;</span>
            </button>
        </div> -->

        <div class="row  justify-content-end">
            <div class="col-12 col-md-4 vaciar-busqueda-usuario" v-if="showClear">
                <button class="btn btn-primary" @click="obtenerUsuarios()">
                    <i class="fas fa-trash px-2"></i>
                    <p>Limpiar Busqueda</p>
                </button>
            </div>
        </div>

        <!-- <div v-if="!noEncontrado" id="encontrados">
            <div class="busqueda-transferencias__texto-paginacion text-muted">
                Se encontraron <strong> {{ total }} usuarios </strong> en
                {{ nombreSectorSelect[0].nombre_sector }}
            </div>
        </div> -->

        <div v-if="buscando">
            <div class="alert alert-info" role="alert">
                Buscando usuario...
            </div>
        </div>
        <div v-else-if="usuarios.length == 0">
            <div class="alert alert-warning" role="alert">
                {{ noEncontrado }}
            </div>
        </div>
        <div class="row" v-else v-for="(usuario, index) in usuarios" :key="usuario.persona_id">
            <div class="col-12">
                <usuario :usuario="usuario" :index="index" v-on:mensaje="mostrarMensaje($event)"></usuario>
            </div>
        </div>

        <div v-if="total > 10 && !noEncontrado">
            <paginacion :pagination="urls" v-on:cambiar-pagina="cambiarPagina($event)"></paginacion>
        </div>
    </div>
</template>

<script>
import usuario from "./elementos/Usuario.vue";
import pagination from "../../../../elementos/pagination/Pagination.vue";
import axios from "axios";

export default {
    components: {
        usuario: usuario,
        paginacion: pagination
    },
    data() {
        return {
            error_exist: false,
            error_message: "",
            cuil: "",
            errores: [],
            mensaje: "",
            usuarios: [],
            noEncontrado: "",
            buscando: false,
            fuerzas: [],
            select: '1',
            fuerza_seleccionada: "",
            total: 0,
            nombreSectorSelect: [{ nombre_sector: "Todos los sectores" }],
            tipoDni: ["DNI", "CI", "LE", "LC"],
            urls: "",
            funcionesDisponibles: [],
            usuario_nuevo: {
                numero_documento: "",
                cuit: "",
                nombre: "",
                apellido: "",
                email: "",
                dob: "",
                tipo_documento_usuario: "",
                fuerza_nuevo_usuario: "",
            },
            usuario: "",
            showClear: false,
            showUserForm: false,
            showRolExternoForm: false,
            rolExternoNuevo: "",
        };
    },
    created() {
        this.obtenerUsuarios();
        this.obtenerFuerzas();

        if (this.$route.params.mensaje) {
            this.mensaje = this.$route.params.mensaje;
        }
    },
    watch: {

        sectorSelect: function (newValor, oldValor) {
            if (newValor === '') {
                this.obtenerUsuarios();
                this.nombreSectorSelect = [
                    { nombre_sector: "Todos los sectores" }
                ];
            } else {
                this.buscando = true;
                this.noEncontrado = "";

                axios.get("obtener-empleados-sector/" + newValor + "?por_pagina=10")
                    .then(response => {
                        this.urls = response.data;
                        this.usuarios = response.data.data;
                        this.nombreSectorSelect = this.sectores.filter(
                            sector => {
                                if (sector.id == newValor) {
                                    return sector;
                                }
                            }
                        );
                        this.total = response.data.total;
                        this.buscando = false;
                    })
                    .catch(error => {
                        this.errores.push(error.response.data);
                        this.buscando = false;
                    });
            }
        },
        fuerzas: function (nuevaListaFuerza, listaFuerzaAnterior) {
            this.fuerzas = nuevaListaFuerza
        }
    },
    methods: {

        validarEnvio() {
            for (let campo in this.usuario_nuevo) {
                if (this.usuario_nuevo[campo] == "") {
                    return false;
                }
            }
            return true;
        },
        agregarUsuario() {
            if (this.validarEnvio()) {
                let datosNuevoUsuario = new FormData();
                for (let campo in this.usuario_nuevo) {
                    datosNuevoUsuario.append(campo, this.usuario_nuevo[campo])
                }
                axios.post('usuarios-externos/crear-usuario', datosNuevoUsuario)
                    .then(response => {
                        this.$swal.fire({
                            title: "el usuario" + response.data["email"] + " ha sido generado correctamente",
                            icon: "success"
                        })
                    }
                    )
                    .catch(error => {
                        this.$swal.fire({
                            title: error.response.data.message,
                            icon: "error"

                        })
                    })
                    .finally(() => {
                        this.usuario_nuevo.numero_documento = ""
                        this.usuario_nuevo.cuit = ""
                        this.usuario_nuevo.nombre = ""
                        this.usuario_nuevo.apellido = ""
                        this.usuario_nuevo.email = ""
                        this.usuario_nuevo.dob = "00-00-0000"
                        this.usuario_nuevo.tipo_documento_usuario = ""
                        this.usuario_nuevo.fuerza_nuevo_usuario = ""
                    })
            } else {
                this.error_exist = true
                this.error_message = "Todos los datos son obligatorios"
            }
        },
        agregarRolExterno() {
            this.errores = "";
            this.errorPassword = false;
            this.errorEmail = false;
            this.acciones = "Agregando organismo...";
            if (!this.rolExternoNuevo == "") {

                rolFormData = new FormData();
                rolFormData.append('rol_externo', this.rolExternoNuevo);
                axios.post('usuarios-externos/crear-rol', rolFormData)
            } else {
                this.error_exist = true
                this.error_message = "El rol es obligatorio"
            }


        },

        mostrarFormularioFuerza() {
            this.showRolExternoForm = !this.showRolExternoForm
            this.showUserForm = false
        },
        mostrarFormularioUsuario() {
            this.showUserForm = !this.showUserForm
            this.showRolExternoForm = false
        },
        obtenerUsuarios() {

            this.buscando = true;
            /*      this.noEncontrado = "";
                 this.sectorSelect = "";
                 this.usuario = "";
      */
            axios.get("obtener-usuarios-externos?por_pagina=10")
                .then(response => {
                    this.urls = response.data;
                    this.buscando = false;
                    this.usuarios = response.data.data;
                    this.total = response.data.total;
                    this.showClear = false
                })
                .catch(error => {
                    this.buscando = false;
                });
        },
        obtenerFuerzas() {
            axios.get("obtener-roles-externos")
                .then(response => {
                    this.fuerzas = response.data.data;
                });
        },
        async buscarEmpleado(usuario) {
            this.buscando = true;
            this.noEncontrado = '';

            if (usuario === '') {
                this.buscando = false;
                return (this.noEncontrado = "Tiene que ingresar un usuario");
            }

            try {
                const { data } = await axios.get(`buscar-empleado/${usuario}`)
                this.total = 1
                this.usuarios.splice(0, this.usuarios.length, data);
                this.buscando = false;
                this.showClear = true;
            }
            catch (e) {
                this.noEncontrado = e.response.data.data;
                this.buscando = false;
            }
        },
        cambiarPagina(url) {
            axios.get(url)
                .then(response => {
                    this.urls = response.data;
                    this.usuarios = response.data.data;
                    $("html, body").animate({
                        scrollTop: $("#encontrados").offset().top
                    });
                })
                .catch(error => {
                    this.errores = error.response.data.error;
                });
        },
        obtenerFunciones() {
            axios.get("obtener-funciones")
                .then(response => {
                    this.funcionesDisponibles = response.data.data;
                })
                .catch(error => {
                    this.errores = error.response.data;
                });
        },
        mostrarMensaje(msj) {
            this.obtenerUsuarios();
            setTimeout(() => (this.mensaje = msj), 250);
        }
    }
};
</script>
<style lang="scss" scoped>
.actions_usuarios {
    flex-direction: row;
}

.export_buton {
    flex-direction: row;

}

.action_card {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
</style>
