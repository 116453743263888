import * as requests from "../../../api/gestionbeneficiosube";

const state = {
    errors: [],
    categoria: null,
    estado: "sin_beneficio",
};

const mutations = {
    resetDatosBeneficioSube(state) {
        (state.errors = []),
            (state.categoria = null),
            (state.estado = "sin_beneficio");
    },
    cargarDatosBeneficioSube(state, data) {
        state.errors = [];
        switch (data.estado) {
            case "I":
                state.estado =
                    data.pendiente < 1 ? "inactivo" : "baja_pendiente";
                break;
            case "A":
                state.estado = data.pendiente < 1 ? "activo" : "alta_pendiente";
                break;
            default:
                state.estado = "sin_beneficio";
                break;
        }
    },
    setErrors(state, errorArray) {
        state.errors = errorArray;
    },
};

const actions = {
    async descargarNovedadesSUBE() {
        const { data } = await requests.getNovedadesSube();
    },
    async cargarNovedadesSUBE($file) {
        const { data } = await requests.getNovedadesSube();
    },
    async obtenerEstadosBeneficiario({ state, commit }, datosBeneficiario) {
        const { data } = await requests.obtenerEstadoBeneficio(
            datosBeneficiario["tipo_documento"],
            datosBeneficiario["numero_documento"]
        );
        switch (data.code) {
            case 202:
                data.data.length > 0
                    ? commit("cargarDatosBeneficioSube", data.data[0])
                    : commit("resetDatosBeneficioSube");
                break;
            default:
                break;
        }
    },
    async habilitarBeneficioSube({ state, commit }, datosBeneficiario) {
        commit[("setErrors", [])];
        requests
            .habilitarServicio(
                datosBeneficiario["tipo_documento"],
                datosBeneficiario["numero_documento"]
            )
            .then((data) => {
                commit("cargarDatosBeneficioSube", data.data.data[0]);
            })
            .catch((error) => {
                commit("setErrors", [error.response.data.message]);
            });
    },
    async deshabilitarBeneficioSube({ state, commit }, datosBeneficiario) {
        commit[("setErrors", [])];
        requests
            .deshabilitarServicio(
                datosBeneficiario["tipo_documento"],
                datosBeneficiario["numero_documento"]
            )
            .then((data) => {
                commit("cargarDatosBeneficioSube", data.data.data[0]);
            })
            .catch((error) => {
                commit("setErrors", [error.response.data.message]);
            });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
