<template>
    <div class="container mt-10">
        <spinner v-if="loading">

        </spinner>
    </div>
</template>
<script>
export default {
    data() {
        return {
            datos: "",
            loading: true
        }
    },

    async mounted() {
        let tokenData = new FormData();
        tokenData.append("token", this.$route.params.token)
        axios.post("verificar-inscripcion-capacitacion", tokenData)
            .then(
                async () => {
                    this.loading = false,
                        this.$swal.fire({
                            title: "La inscripcion fue verificada correctamente. ",
                            text: "Todas las novedades y detalles de la capacitación serán enviadas a la casilla del mail que ha registrado",
                            icon: "success"
                        }).then(() => {
                            this.$router.push({ path: '/' })
                        })
                }
            ).catch(error => {
                this.$swal.fire({
                    title: error.response.data.error,
                    icon: "error"
                })
            })
    },
}
</script>
