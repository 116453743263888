<template>
    <div>
        <div class="w-100">
            <h3>Certificados de Haberes</h3>
            <div v-if="masDeUnBeneficio">
                <div class="menu-beneficios">
                    <button v-if="beneficios" type="button" class="btn btn-primary boton-beneficio mr-3"
                        v-for="beneficio in beneficios" @click="obtenerCertificados(beneficio.beneficio_id)">
                        Beneficio {{ beneficio.beneficio_id | formatearBeneficio }}
                    </button>

                    <button v-if="apoderados" type="button" class="btn btn-primary boton-beneficio mr-3"
                        v-for="apoderado in apoderados" @click="obtenerCertificados(apoderado.beneficio_id)">
                        Apoderado {{ apoderado.beneficio_id | formatearBeneficio }}
                    </button>
                </div>
            </div>

            <div class="alert alert-success alert-dismissible fade show" role="alert"
                v-if="$route.query.certificado == 'generado'">
                Certificado generado correctamente.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="alert alert-info mt-4" v-if="certificados == null">
                Buscando certificados de haberes
            </div>
            <sin-certificados class="mt-4" v-else-if="certificados.length === 0"
                :beneficio="beneficio_id"></sin-certificados>
            <div class="mt-4" v-else>
                <certificado-haber-beneficiario v-for="certificado in certificados" :key="certificado.numero"
                    :certificado="certificado" :beneficio="beneficio_id"></certificado-haber-beneficiario>
            </div>
        </div>
    </div>
</template>

<script>
import SinCertificados from "./elementos/SinCertificados.vue";
import CertificadoHaberBeneficiario from "./elementos/CertificadoHaberBeneficiario.vue";

export default {
    components: {
        "sin-certificados": SinCertificados,
        "certificado-haber-beneficiario": CertificadoHaberBeneficiario
    },
    data() {
        return {
            certificados: null,
            beneficios: null,
            beneficio_id: null,
            masDeUnBeneficio: false,
            errorCertificados: null,
            apoderados: null
        };
    },
    created() {
        this.obtenerBeneficios();

        if (this.$route.query.id) {
            this.obtenerCertificados(this.$route.query.id);
            return;
        } else {
            if (this.beneficios.length >= 1) {
                this.obtenerCertificados(this.beneficios[0].beneficio_id);
                return;
            }
            if (this.apoderados.length >= 1) {
                this.obtenerCertificados(this.apoderados[0].beneficio_id);
                return;
            }
        }
    },
    methods: {
        obtenerBeneficios: function () {
            var beneficios = (this.beneficios = this.$store.state.beneficios.beneficios);
            var apoderados = (this.apoderados = this.$store.state.beneficios.apoderados);
            if (beneficios.length > 1) {
                this.masDeUnBeneficio = true;
                return;
            }
            if (apoderados.length > 1) {
                this.masDeUnBeneficio = true;
                return;
            }
            if (apoderados.length + beneficios.length > 1) {
                this.masDeUnBeneficio = true;
                return;
            }
        },
        obtenerCertificados: function (beneficio_id) {
            this.errorCertificados = null;
            this.certificados = null;
            this.beneficio_id = beneficio_id;

            axios.get(beneficio_id + "/mis-tramites/mis-certificados-haberes")
                .then(response => {
                    this.certificados = response.data.data;
                })
                .catch(error => {
                    this.errorCertificados = "Hubo un inconveniente en la aplicación. Vuelva a intentar en unos minutos";
                });
        },
        descargarCertificado: function (numero_certificado) {
            this.descargandoCertificado = 1;
            axios.get(this.beneficio + "/mis-tramites/mis-certificados-haberes/" + numero_certificado + "/descargar", {
                responseType: "blob,  application/json",
            })
                .then(response => {
                    var filename = "CERTHAB-" + numero_certificado + "-" + this.certificado.beneficio_id + ".pdf";
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(error => {
                    this.$swal.fire({
                        title: error.response.data.error

                    });
                })
        },
    }
};
</script>
