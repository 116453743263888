<template>
    <div>

        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Está en: Mi Caja</li>
            </ol>
        </nav>


        <novedad-noticias-beneficiarios v-if="rolBeneficiario">

        </novedad-noticias-beneficiarios>

        <novedad-ultimo-recibo v-if="rolBeneficiario"></novedad-ultimo-recibo>

        <novedad-punto-a-punto v-if="rolUsuario"></novedad-punto-a-punto>

        <novedad-empleados-agosto v-if="rolEmpleado"></novedad-empleados-agosto>

        <novedad-mis-recibos v-if="rolEmpleado"></novedad-mis-recibos>

        <novedad-aplicacion-usuarios v-if="appOrganismoConsulta"></novedad-aplicacion-usuarios>

        <!-- MIGRACION 1 - GESTION RECIBOS EXTERNOS: SE UNIFICA LA RUTA PARA LOS ACCESOS -->
        <div v-if="rolExterno">
            <h2>Gestion de Recibos </h2>
            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'MisAplicacionesGestionRecibosExternos', query: { 'option': 'Gendarmeria' } }"
                v-if="mostrarRecibosGendarmeria">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-e100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-envelope-open-text fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Recibos Gendarmeria</h5>
                        <p class="card-text text-muted"> Buscador Recibos Gendarmeria.</p>
                    </div>
                </div>
            </router-link>

            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'MisAplicacionesGestionRecibosExternos', query: { 'option': 'Penitenciaria' } }"
                v-if="mostrarRecibosPenitenciaria">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-envelope-open-text fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Recibos Penitenciaria</h5>
                        <p class="card-text text-muted"> Buscador Recibos Penitenciaria.</p>
                    </div>
                </div>
            </router-link>
            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'MisAplicacionesGestionRecibosExternos', query: { 'option': 'Policia' } }"
                v-if="mostrarRecibosPolicia">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-envelope-open-text fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Recibos Policia</h5>
                        <p class="card-text text-muted"> Buscador Recibos Policia.</p>
                    </div>
                </div>
            </router-link>
        </div>
        <!-- MIGRACION 1 - GESTION RECIBOS EXTERNOS: HASTA ACA -->


    </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import NovedadMisRecibos from "./novedades/NovedadMisRecibos.vue";
import NovedadPuntoAPunto from "./novedades/NovedadPuntoAPunto.vue";
import NovedadProgramaTransaccional from "./novedades/NovedadProgramaTransaccional.vue";
import NovedadUltimoRecibo from "./novedades/NovedadUltimoRecibo.vue";
import NovedadAplicacionUsuarios from "./novedades/NovedadAplicacionUsuarios.vue";
import NovedadEmpleadosAgosto from "./novedades/NovedadEmpleadosAgosto.vue";
import NovedadNoticiasBeneficiarios from "./novedades/NovedadesBeneficiariosMiCaja.vue"

export default {
    components: {
        "novedad-mis-recibos": NovedadMisRecibos,
        "novedad-punto-a-punto": NovedadPuntoAPunto,
        "novedad-programa-transaccional": NovedadProgramaTransaccional,
        "novedad-ultimo-recibo": NovedadUltimoRecibo,
        "novedad-aplicacion-usuarios": NovedadAplicacionUsuarios,
        "novedad-empleados-agosto": NovedadEmpleadosAgosto,
        "novedad-noticias-beneficiarios": NovedadNoticiasBeneficiarios
    },
    data() {
        return {
            rol_externo: []
        }
    },
    created() {
        this.obtenerRolesUser()
    },

    methods: {
        ...mapActions('gestionexternos', [
            'obtenerRolesUser'
        ]),

    },
    computed: mapState({
        rolBeneficiario: state => {
            return state.user.roles.includes('BENEFICIARIO')
        },
        rolUsuario: state => {
            return state.user.roles.includes('USUARIO')
        },
        rolEmpleado: state => {
            return state.user.roles.includes('EMPLEADO')
        },
        rolExterno: state => {
            return state.user.roles.includes('EXTERNO')
        },
        appOrganismoConsulta: state => {
            return state.user.aplicaciones.some(a => a.aplicacion === 'USUARIOS' && a.funcion === 'USUARIOS-ORGANISMOS-CONSULTA') && state.user.roles.includes('EMPLEADO')
        },
        /* MIGRACION 1  GESTION RECIBOS EXTERNOS: SE UNIFICA LA RUTA PARA LOS ACCESOS */
        mostrarRecibosGendarmeria: state => {
            return state.user.aplicaciones.some(a => a.aplicacion === 'RECIBOSEXTERNOS')
                && state.gestionexternos.roles_externos.some(rol => rol === 'GENDARMERIA')
        },
        mostrarRecibosPenitenciaria: state => {
            return state.user.aplicaciones.some(a => a.aplicacion === 'RECIBOSEXTERNOS')
                && state.gestionexternos.roles_externos.some(rol => rol === 'PENITENCIARIA')
        },
        mostrarRecibosPolicia: state => {
            return state.user.aplicaciones.some(a => a.aplicacion === 'RECIBOSEXTERNOS')
                && state.gestionexternos.roles_externos.some(rol => rol === 'POLICIA')
        },

    }),
};
</script>
