<template>
    <div class="container" v-if="accesoPermitido">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    Está en:
                    <router-link :to="{ name: 'Inicio' }">Mi Caja</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{ name: 'MisAplicacionesMenu' }">Mis Aplicaciones</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{ name: 'MisAplicacionesUsuariosContenedor' }">Usuarios</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Externos
                </li>
            </ol>
        </nav>

        <h2 class="titulo-aplicacion">Usuarios Externos</h2>
        <router-view></router-view>
    </div>
    <div class="w-100" v-else>
        <div class="alert alert-primary text-center">{{ mensaje }}</div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            accesoPermitido: false,
            mensaje: "Verificando acceso a la aplicación..."
        };
    },
    /* components: {
         "menu-usuarios": Menu
     },*/
    created() {
        this.accederAdministracionUsuarios();
    },
    methods: {
        accederAdministracionUsuarios: function () {
            axios
                .get("acceder-administracion-usuarios")
                .then(response => {
                    this.accesoPermitido = true;
                })
                .catch(error => {
                    this.accesoPermitido = false;
                    this.mensaje =
                        "No puede acceder a la aplicación Usuarios en este momento.";
                });
        }
    }
};
</script>
