//MIGRACION 1 - GESTION RECIBOS EXTERNOS: SE UNIFICA Y SE ELIMINAN las peticiones DE GENDARMERIA Y PENITENCIARIA

const getRecibosExternosPorCUIT = (cuit, opcion) => {
    return axios.get(
        `gestion-externos/buscar-recibos/${opcion}/buscar-cuit/${cuit}`
    );
};

const getRecibosExternosPorBeneficio = (beneficio, opcion) => {
    return axios.get(
        `gestion-externos/buscar-recibos/${opcion}/buscar-beneficio/${beneficio}`
    );
};
const getRolesUsuarioExterno = (beneficio, opcion) => {
    return axios.get(`gestion-externos/roles-usuario`);
};

export {
    getRecibosExternosPorCUIT,
    getRecibosExternosPorBeneficio,
    getRolesUsuarioExterno,
};
