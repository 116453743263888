<!-- MIGRACION 1 - GESTION RECIBOS EXTERNOS: SE UNIFICA Y SE ELIMINAN LOS componentes  DE GENDARMERIA Y PENITENCIARIA -->
<template>
    <div>
        <buscador-recibos-externos :opcion="opcion"
            @loadStateChange="value => { this.loadingRecibos = value }"></buscador-recibos-externos>
        <br />
        <div class="alert alert-primary w-100 d-inline-flex align-items-center justify-content-center"
            v-if="loadingRecibos">
            <div class="mr-2 spinner-border text-info" role="status">
            </div>
            <div class="ml-2">Buscando Los Datos ...</div>
        </div>
        <div v-else>
            <div class="alert alert-primary" v-if="recibos.length == 0 && !recibosBuscados">
                Puede realizar la búsqueda de información por número de documento o número de CUIL
            </div>
            <div v-if="errores.length > 0">
                <div v-for="error in errores" class="alert alert-danger">
                    {{ error }}
                </div>
            </div>
            <lista-recibos-externos v-else :recibosEncontrados="recibos" :persona="persona" :opcion="opcion">
            </lista-recibos-externos>
        </div>
    </div>
</template>
<script>
import BuscadorRecibosExternos from './buscador/BuscadorRecibosExternos.vue';
import ListaRecibosExternos from './lista/ListaRecibosExternos.vue';
import { mapActions, mapState } from 'vuex';

/* MIGRACION 1  GESTION RECIBOS EXTERNOS: SE UNIFICAN LOS METODOS Y COMPONENTES. CAMBIO STORE*/
export default {
    name: "MisAplicacionesGestionRecibosExternos",

    beforeMount() {
        this.limpiarDatos();
    },
    components: {
        BuscadorRecibosExternos,
        ListaRecibosExternos,

    },
    data() {
        return {
            opcion: this.$route.query.option,
            loadingRecibos: false
        }
    },

    methods: {
        ...mapActions('gestionexternos', [
            'limpiarDatos',
        ]),
    },
    watch: {
        recibos(estadoRecibosExternos, nuevoEstadoRecibosExternos) {
            return nuevoEstadoRecibosExternos
        },
        loadingRecibos(valorAnterior, nuevoValor) {
            return nuevoValor
        }
    },
    computed: {
        ...mapState({
            recibos: state => {
                return state.gestionexternos.recibos
            },
            persona: state => {
                return state.gestionexternos.persona
            },
            errores: state => {
                return state.gestionexternos.errors
            },
            loading: state => {
                return state.gestionexternos.loading
            },
            recibosBuscados: state => {
                return state.gestionexternos.recibosBuscados
            }
        })
    },


}
</script>
