import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import persona from "./modules/persona";
import beneficios from "./modules/beneficios";
import {
    administradorreciboshaberes,
    gestionbeneficiarios,
    gestionbeneficiosube,
    gestionexternos, //MIGRACION 1 - GESTION RECIBOS EXTERNOS: SE UNIFICA Y SE ELIMINAN LOS STORES DE GENDARMERIA Y PENITENCIARIA
    notificaciones,
    novedades,
} from "./modules/aplicaciones/index";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        administradorreciboshaberes,
        beneficios,
        gestionbeneficiarios,
        gestionbeneficiosube,
        gestionexternos, //MIGRACION 1 - GESTION RECIBOS EXTERNOS: SE UNIFICA Y SE ELIMINAN LOS STORES DE GENDARMERIA Y PENITENCIARIA
        notificaciones,
        novedades,
        persona,
        user,
    },
});
