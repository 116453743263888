<template>
    <div class="container" v-if="accesoPermitido">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    Está en: <router-link :to="{ name: 'Inicio' }">Mi Caja</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{ name: 'MisAplicacionesMenu' }">Mis Aplicaciones</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{ name: 'MisAplicacionesUsuariosContenedor' }">Usuarios</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Organismos
                </li>
            </ol>
        </nav>
        <h2 class="titulo-aplicacion">Usuarios de organismos</h2>

        <Menu @download="exportarUsuariosOrganismos()" />

        <router-view></router-view>
    </div>
    <div class="w-100" v-else>
        <div class="alert alert-primary text-center">{{ mensaje }}</div>
    </div>
</template>

<script>
import Menu from "./elementos/Menu.vue";
import { mapState } from "vuex";

export default {
    data() {
        return {
            accesoPermitido: false,
            mensaje: "Verificando acceso a la aplicación..."
        };
    },
    components: {
        Menu
    },
    created() {
        this.accederAdministracionUsuarios();
    },
    computed: mapState({
        esAdministrador: state => {
            return state.user.roles.includes('ADMINISTRADOR')
        }
    }),
    methods: {
        exportarUsuariosOrganismos() {
            axios.get('usuarios/organismos/exportar', { responseType: 'blob' })
                .then(response => {

                    const url = window.URL.createObjectURL(new Blob([response.data], { encoding: "ANSI", type: "text/csv;charset=ANSI" }));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", 'lista_organismos.csv');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                })
                .catch(error => {
                    this.errores.push(error.response.data);
                    this.buscando = false;
                })

        },
        accederAdministracionUsuarios: function () {
            axios.get("acceder-administracion-usuarios")
                .then(response => {
                    this.accesoPermitido = true;
                })
                .catch(error => {
                    this.accesoPermitido = false;
                    this.mensaje = "No puede acceder a la aplicación Usuarios en este momento.";
                });
        }
    }
};
</script>
