<template>
    <div class="w-100">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Estás en: <router-link :to="{ name: 'Inicio' }">Mi Caja</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Mis Documentos</li>
            </ol>
        </nav>

        <div class="row">
            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'MisRecibos' }" v-if="mostrarMisRecibos">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-file-alt fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Mis Recibos</h5>
                        <p class="card-text text-muted">Haberes mensuales <span v-if="esBeneficiario">y
                                complementarias</span></p>
                    </div>
                </div>
            </router-link>

            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'MisCertificadosGanancias' }" v-if="mostrarMisCertificadosGanancias">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-file-alt fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Mis Certificados Ganancias</h5>
                        <p class="card-text text-muted">Certificados de impuesto a las ganancias</p>
                    </div>
                </div>
            </router-link>

            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'MisCertificadosHaberes' }" v-if="mostrarMisCertificadosHaberes">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-file-alt fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Mis Certificados de Haberes</h5>
                        <p class="card-text text-muted">Consulta de certificados de haberes</p>
                    </div>
                </div>
            </router-link>

            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'MisCertificadosComplementarias' }" v-if="esBeneficiario">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-file-alt fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Mis Certificados de Complementarias</h5>
                        <p class="card-text text-muted">Consulta de certificados de complementarias</p>
                    </div>
                </div>
            </router-link>
            <div class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none; color:#0072bb"
                @click="generarCertificadoNegativaSalario" v-if="esBeneficiario">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-download fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Certificacion Negativa Salario Familiar</h5>
                        <p class="card-text text-muted">Descargue la certificacion negativa de Salario Familiar</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
    methods: {
        generarCertificadoNegativaSalario() {
            this.$swal.fire({
                title: "Para que beneficio quiere generar el certificado ?",
                input: "radio",
                inputOptions: this.beneficios,
                inputValidator: (value) => {
                    if (!value) {
                        return "Tiene que seleccionar un beneficio";
                    }
                }
            }).then(({ value }) => {
                let data = new FormData()
                data.append('beneficio_id', value)
                axios.post("generar-certificado-negativa", data, {
                    responseType: 'blob'
                })
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', "pruebas.pdf");
                        document.body.appendChild(link);
                        link.click();
                    })
            })
        }
    },
    computed: mapState({
        mostrarMisRecibos: state => {
            return state.user.roles.some(r => ['BENEFICIARIO', 'APODERADO', 'EMPLEADO'].includes(r))
        },
        mostrarMisCertificadosGanancias: state => {
            return state.user.roles.some(r => ['BENEFICIARIO', 'APODERADO'].includes(r))
        },
        mostrarMisCertificadosHaberes: state => {
            return state.user.roles.some(r => ['BENEFICIARIO', 'APODERADO'].includes(r))
        },
        esBeneficiario: state => {
            return state.user.roles.includes('BENEFICIARIO')
        },
        beneficios: state => {
            let list = {}
            state.beneficios.beneficios.forEach(beneficio => {
                list[beneficio.beneficio_id] = `${beneficio.clase_id}-${beneficio.beneficio}-${beneficio.barra}`
            });
            return list
        }
    }),
};
</script>
