<template>
    <div class="contenedor__menu-transferencias">
        <router-link tag="button" class="btn btn__submenu" :to="{ path: 'buscar' }">
            <i class="fas fa-search fa-2x icono-micaja"></i><br>
            Buscar
        </router-link>

        <router-link tag="button" class="btn btn__submenu" :to="{ path: 'nuevo' }">
            <i class="fas fa-user-plus fa-2x icono-micaja"></i><br>
            Nuevo Usuario
        </router-link>
        <button tag="button" class="btn btn__submenu" @click="$emit('download')">
            <i class="fas fa-user-plus fa-2x icono-micaja"></i><br>
            Exportar Usuarios
        </button>
    </div>
</template>
<script>
export default {
    name: "menuAdministracionOrganismos",
    emits: ["download"]
}
</script>
