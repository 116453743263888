<template>
    <div class="w-100">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    Está en: <a href="#/mi-caja/inicio/">Mi Caja</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Mis Aplicaciones
                </li>
            </ol>
        </nav>

        <div class="row">
            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'PuntoAPuntoNovedades' }" v-if="mostrarPuntoAPunto">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-share-alt fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Punto a Punto</h5>
                        <p class="card-text text-muted">Envío y recepción de documentación</p>
                    </div>
                </div>
            </router-link>

            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'GestionBeneficiariosBuscar' }" v-if="mostrarGestionBeneficiarios">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-user-friends fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Gestión de Beneficiarios</h5>
                        <p class="card-text text-muted">Administración de usuarios/beneficiarios</p>
                    </div>
                </div>
            </router-link>

            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'DptoLiquidacionesRecibosComplesBuscar' }" v-if="mostrarDptoLiquidaciones">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-file-alt fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Consulta de recibos</h5>
                        <p class="card-text text-muted">Complementarias y suplementarias</p>
                    </div>
                </div>
            </router-link>

            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'MisAplicacionesUsuariosContenedor' }" v-if="mostrarUsuarios">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-user-friends fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Usuarios</h5>
                        <p class="card-text text-muted">Administración de usuarios</p>
                    </div>
                </div>
            </router-link>

            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'MisAplicacionesTramitesADistanciaMenu' }" v-if="mostrarTramitesADistancia">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-laptop-house fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Trámites a Distancia</h5>
                        <p class="card-text text-muted">Trámites de beneficiarios vía web</p>
                    </div>
                </div>
            </router-link>

            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'MisAplicacionesOperacionesMenu' }" v-if="mostrarOperaciones">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-clipboard-list fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Operaciones</h5>
                        <p class="card-text text-muted">Procesos y gestión de Mi Caja</p>
                    </div>
                </div>
            </router-link>

            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'MisAplicacionesNotificacionesMenu' }" v-if="mostrarAdministrador">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-envelope-open-text fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Notificaciones</h5>
                        <p class="card-text text-muted">Generacion de notificaciones</p>
                    </div>
                </div>
            </router-link>
            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'MisAplicacionesGestionNovedadesMenu' }" v-if="mostrarEdicionNovedades">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-envelope-open-text fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Sección Inicio</h5>
                        <p class="card-text text-muted"> Publicación de novedades.</p>
                    </div>
                </div>
            </router-link>

            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;"
                :to="{ name: 'MisAplicacionesAdministracionRecibos' }" v-if="mostrarAdministracionRecibosInfoPasivos">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-envelope-open-text fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Busqueda Recibos por Rango</h5>
                        <p class="card-text text-muted">Buscar Recibos por CUIT o DNI por rango de fechas </p>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: mapState({
        mostrarPuntoAPunto: state => {
            return state.user.aplicaciones.some(a => a.aplicacion === 'PUNTO-A-PUNTO')
        },
        mostrarGestionBeneficiarios: state => {
            return state.user.aplicaciones.some(a => a.aplicacion === 'GESTIONBENEFICIARIOS')
        },
        mostrarDptoLiquidaciones: state => {
            return state.user.aplicaciones.some(a => a.aplicacion === 'DPTOLIQUIDACIONES')
        },
        mostrarUsuarios: state => {
            return state.user.aplicaciones.some(a => a.aplicacion === 'USUARIOS')
        },
        mostrarTramitesADistancia: state => {
            return state.user.aplicaciones.some(a => a.aplicacion === 'TRAMITES-A-DISTANCIA')
        },
        mostrarOperaciones: state => {
            return state.user.aplicaciones.some(a => a.aplicacion === 'OPERACIONES')
        },
        mostrarAdministrador: state => {
            return state.user.roles.includes('ADMINISTRADOR')
        },
        mostrarEdicionNovedades: state => {
            return state.user.aplicaciones.some(a => a.aplicacion === 'GESTION-NOVEDADES')
        },
        mostrarAdministracionRecibosInfoPasivos: state => {
            return state.user.aplicaciones.some(a => a.aplicacion === 'ADMINISTRADOR-RECIBOS-HABERES')
        }
    }),
};
</script>
